@import '../../../../../theme/size.scss';

.CellMemberContactInformationModal {
  --border-radius: 0.25rem;
  --width: auto;
  --height: auto;
  --min-width: 50vw;
  --max-width: 50vw;

  @media (max-width: $sm) {
    --min-width: 90vw;
    --max-width: 90vw;
  }

  #ion-react-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .close {
      position: fixed;
      top: 1rem;
      right: 1rem;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 1.5rem;
      width: 100%;

      .description {
       margin-bottom: 1.25rem; 
      }

      .textInputBox {
        width: 100%;
        margin: 0.75rem 0;
      }

    }

    .title {
      padding: 1.5rem;
      font-size: 1.5rem;
      color: var(--ion-title-color);
      width: 100%;
      border-bottom: var(--ion-border);
    }

    .buttonBox {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
      margin-top: 2rem;
      gap: 1rem;
    }
  }
}
