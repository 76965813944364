@import '../../theme/size.scss';

.textInputBox {
  position: relative;

  .inputHeader {
    display: flex;
    justify-content: space-between;
    //flex: 1;
  }
  .label {
    padding: 0.5rem 0rem 0.1rem;
    font-size: 1rem;
  }
  .suggestionsList {
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    margin-top: 0.75rem;
    border-radius: 0.15rem;
    border: var(--ion-border);
    background-color: var(--ion-disabled-background);
    position: relative;
    height: '200px';
    overflow-y: scroll;
  }
  .suggestions {
    padding-right: 20px;
    max-height: 2.5rem;
    min-height: 40px;
    align-items: center;
    padding: 10px;
    border: black;
    border: var(--ion-border);
  }
  input,
  textarea {
    &::-webkit-contacts-auto-fill-button,
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      height: 0;
      width: 0;
      margin: 0;
    }
  }

  input[type='date'] {
    padding-right: 20px;
    max-height: 2.5rem;
    min-height: 40px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ion-textarea {
    max-height: 20rem;
    margin: 0;

    textarea {
      padding-right: 1rem;
      overflow: auto;
    }
  }

  &.isError {
    &,
    .label {
      color: var(--ion-color-danger);
    }

    .textInputGroup,
    ion-textarea {
      border: 1px solid var(--ion-color-danger);
    }
  }

  .textInputGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    margin-top: 0.75rem;
    border-radius: 0.15rem;
    border: var(--ion-border);
    background-color: var(--ion-disabled-background);
    position: relative;

    @media (max-width: $sm) {
      margin-right: 1rem;
    }

    .tooltip {
      position: absolute;
      top: 50%;
      right: -1.75rem;
      transform: translateY(-50%);
      z-index: 100;
    }

    .iconBox {
      cursor: pointer;
      display: flex;
      padding: 0rem 0.5rem;
      ion-icon {
        font-size: 1.25rem;
        --ion-text-color: var(--ion-darker-color);
      }
    }
  }

  .textInput {
    height: 100%;
    padding: 0rem 0.25rem !important;

    font-size: 1rem;
    background-color: #fff;
    // border: var(--ion-border);
    --placeholder-color: var(--ion-dark-color);

    &.uppercase {
      text-transform: uppercase;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    &.disabled {
      background-color: var(--ion-disabled-background);
    }

    @media (max-width: $md) {
      padding: 0.25rem 0.5rem !important;
    }

    &.file {
      margin: 8px 0px;
      &,
      input {
        border: none;
        padding: 0.25rem 0 !important;
      }
    }
  }

  .suppleant {
    padding: 0.5rem;
  }

  .iconContainer {
    background-color: #fff;
    height: 100%;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
  }

  .errorMessage {
    position: absolute;
    bottom: -1rem;
    right: 0px;
    line-height: 1rem;
    font-size: 0.8rem;
  }

  .warningMessage {
    position: absolute;
    bottom: -1rem;
    right: 0px;
    line-height: 1rem;
    font-size: 0.8rem;
    color: var(--ion-color-warning);
  }
}
