.phoneInputBox {
  height: 100%;

  &.isError {
    input, .label {
      color: var(--ion-color-danger);
    }
    input {
      border: 1px solid var(--ion-color-danger);
    }
  }

  .form-control {
    height: 2.5rem;
    padding: .5rem;
    border-radius: .15rem;
    border: var(--ion-border);
    font-size: 1rem;
    background-color: white;
    width: 100%;
    min-width: 200px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    
    &:disabled {
      opacity: .6;
    }
  }

  .country-list {
    z-index: 10000;
  }
  .selected-dial-code {
    user-select: none;
    pointer-events: none;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    padding-left: .5rem;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex;
  }

  .label {
    padding: 0.5rem 0rem 0.1rem;
    font-size: 1rem;
  }

  .intl-tel-input {
    margin-top: 0.75rem;
  }
  input {
    outline: none !important;
  }
}